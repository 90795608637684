export const FETCH_PLACE_START = 'FETCH_PLACE_START';
export const FETCH_PLACE_SUCCESS = 'FETCH_PLACE_SUCCESS';
export const FETCH_PLACE_FAILURE = 'FETCH_PLACE_FAILURE';
export const FETCH_PLACE_END = 'FETCH_PLACE_END';

export const CREATE_PLACE_START = 'CREATE_PLACE_START';
export const CREATE_PLACE_SUCCESS = 'CREATE_PLACE_SUCCESS';
export const CREATE_PLACE_FAILURE = 'CREATE_PLACE_FAILURE';
export const CREATE_PLACE_END = 'CREATE_PLACE_END';

export const UPDATE_PLACE_START = 'UPDATE_PLACE_START';
export const UPDATE_PLACE_SUCCESS = 'UPDATE_PLACE_SUCCESS';
export const UPDATE_PLACE_FAILURE = 'UPDATE_PLACE_FAILURE';
export const UPDATE_PLACE_END = 'UPDATE_PLACE_END';

export const DELETE_PLACE_START = 'DELETE_PLACE_START';
export const DELETE_PLACE_SUCCESS = 'DELETE_PLACE_SUCCESS';
export const DELETE_PLACE_FAILURE = 'DELETE_PLACE_FAILURE';
export const DELETE_PLACE_END = 'DELETE_PLACE_END';
