const initState = {
    loading: false,
    message: '',
    blog: '',
    error: '',
    getBlog: {
      loading: false,
      message: '',
      error: ''
    }
  };
    
  export default initState;
    