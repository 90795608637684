/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTransition, useSpring, animated } from '@react-spring/web'
import { fetchProperty } from '../../../redux/actions/property'
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Gallery from '../../Gallery/indexx'
import './ListOfTheDay.css'


function ListingOfTheDay() {
  const [activeIndex, setActiveIndex] = useState(0);
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchProperty('Indabyo-Heights:-Luxurious-3-Bedroom-Apartments-Gasabo'))
  }, [dispatch])

  const data = useSelector(state => state.property)

  const { loading, property, getProperty, message } = data

  const imgs = property && property.urls ? property.urls : []

  

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setActiveIndex((active) => (active + 1) % imgs.length)
  //   }, 5000)

  //   return () => clearInterval(interval)
  // }, [])

  const transitions = useTransition([activeIndex], {
    from: { opacity: 0, transform: 'scale(0)', display: 'none' },
    enter: { opacity: 1, transform: 'scale(1)', display: 'block' },
    leave: { opacity: 0, transform: 'scale(0)', display: 'none' },
  })

  const [springs, api] = useSpring(() => ({
    from: { opacity: 1 },
    config: { duration: 1000 }
  }))

  const handleClick = (index) => {
    setActiveIndex(index)
  }

  const handleMouseEnter = () => {
    api.start({
      from: { opacity: 1 },
      to: { opacity: .65 }
    })
  }

  const handleMouseLeave = () => {
    api.start({
      from: { opacity: .65 },
      to: { opacity: 1 }
    })
  }
  

  return (
    <section id="listing-of-the-day" className="aios-scroll-section" data-aios-scroll-title="Listing of the Day">
            <div className="textwidget custom-html-widget">
                <div className="ld-container">
                    <div className="global-accent-bar attachment-fixed">
                        <canvas width="70" height="768" className="lazyload" data-bgset="https://serioestates.com/wp-content/themes/rwserio-pending.com/images/accent-gradient.jpg"></canvas>
                    </div>
                    <div className="global-accent-letter">
                        <span>L</span>
                    </div>
                    <div className="ld-bg attachment-fixed">
                        <canvas width="1600" height="1165" className="lazyload" data-bgset="https://serioestates.com/wp-content/themes/rwserio-pending.com/images/ld-bg.jpg"></canvas>
                    </div>
                    <div className="custom-container">
                        <div className="ld-main">
                            <div className="global-title is-centered" data-aos-once="true" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="200">
                                <h2>
                                    <em className="global-logo-icon">
                                        <i className="global-outline"></i>
                                    </em>
                                    <span>Listing Of the Day</span>
                                </h2>
                            </div>
                            <div className="ld-list">
                                <div className="ld-list-item" id="listing-24404087">
                                    <div className="ld-list-title">
                                        <span>{property.title} - {property.location}</span>
                                    </div>
                                    <div className="ld-list-details">
                                        <div className="ld-list-info">
                                            <span>
                                                <em className="ai-icon-bed-w"></em>{property.bedrooms} <i>Beds</i>
                                            </span>
                                            <span>
                                                <em className="ai-icon-bath-w"></em>{property.bathrooms} <i>Baths</i>
                                            </span>
                                            <span>
                                                <em className="ai-icon-sqft-w"></em>{property.property_size} <i>Sq.m.</i>
                                            </span>
                                            {/* <span>
                                                <em className="ai-icon-acres"></em>1.79 <i>Acres</i>
                                            </span> */}
                                        </div>
                                        <div className="ld-list-btn">
                                            <Link to='/listings' className="global-btn">See More</Link>
                                        </div>
                                    </div>
                                    <Row className='d-flex flex-row'>
                                      <div className="h-[600px] w-full z-9999">
                                        <Gallery imgs={imgs} property={property} className="bg-black-100 p-6 rounded-2xl" />
                                      </div>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  )
}

export default ListingOfTheDay