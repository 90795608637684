/* eslint-disable no-unused-vars */
/* eslint-disable default-case */
// src/components/SearchFilters/index.js

import React, {useState, useEffect, useCallback} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { fetchAllProperties, searchProperties } from '../../redux/actions/properties'
import { fetchAllCategories } from '../../redux/actions/categories'
import { fetchAllPlaces } from '../../redux/actions/places'
import { useNavigate, Link } from 'react-router-dom'
import './SearchFilters.css';
import Modal from '../Modal';

const SearchFilters = ({ filters, onFilterChange }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const properties = useSelector(state => state.properties)
  const categories = useSelector(state => state.categories)
  const places = useSelector(state => state.places)
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   onFilterChange({ [name]: value });
  // };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const [formData, setFormData] = useState({
    location: '',
    propertyType: '',
    priceRange: 0,
    propertySize: 0,
    propertyCategories: [],
    forSale: false,
    forRent: false,
    isLand: false,
  });

  const buildQuery = useCallback((data) => {
    const queryParts = [];
  
    if (data.location) {
      queryParts.push(`location=${encodeURIComponent(data.location)}`);
    }
  
    if (data.propertyType) {
      queryParts.push(`property_type=${encodeURIComponent(data.propertyType)}`);
    }
  
    if (data.propertySize) {
      const sizeMappings = {
        0: [20, 50],
        1: [50, 100],
        2: [100, 150],
        3: [150, 200],
      };
      queryParts.push(`property_size=[${sizeMappings[data.propertySize].join(',')}]`);
    }
  
    if (data.priceRange) {
      const priceMappings = {
        0: [0, 50000000],
        1: [50000000, 100000000],
        2: [100000000, 150000000],
        3: [150000000, 200000000],
      };
      queryParts.push(`price=[${priceMappings[data.priceRange].join(',')}]`);
    }
  
    if (data.isLand) {
      queryParts.push('isLand=true');
    }
  
    if (data.forSale) {
      queryParts.push('isForSale=true');
    }
  
    if (data.forRent) {
      queryParts.push('isForRent=true');
    }

    if (data.propertyCategories && data.propertyCategories.length > 0) {
      queryParts.push(`property_categories=${data.propertyCategories.join(',')}`);
    }
  
    return queryParts.join('&');
  }, []);

  const handleInputChange = useCallback((e) => {
    const { name, value, type, checked } = e.target;
    
    setFormData(prevState => {
      let newState;
      if (type === 'checkbox') {
        if (name === 'propertyCategories') {
          newState = {
            ...prevState,
            [name]: checked 
              ? [...prevState[name], value]
              : prevState[name].filter(item => item !== value)
          };
        } else if (name === 'isLand') {
          newState = {
            ...prevState,
            [name]: checked
          };
        } else if (name === 'forSale') {
          newState = {
            ...prevState,
            [name]: checked
          };
        } else if (name === 'forRent') {
          newState = {
            ...prevState,
            [name]: checked
          };
        } else {
          newState = {
            ...prevState,
            [name]: checked
          };
        }
      } else {
        newState = {
          ...prevState,
          [name]: value
        };
      }
      
      // Dispatch the search action with the updated state
      const updatedQuery = buildQuery(newState);
      updatedQuery ? dispatch(searchProperties(updatedQuery)) : dispatch(fetchAllProperties());
      return newState;
    });
  }, [buildQuery, dispatch]);

  useEffect(() => {
    dispatch(fetchAllCategories())
    dispatch(fetchAllPlaces())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // eslint-disable-next-line no-unused-vars
  const { listOfProperties = [], next, currentPage, getProperties = {} } = properties || {}
  const { loading } = getProperties
  
  // eslint-disable-next-line no-unused-vars
  const { listOfCategories = [], getCategories = {} } = categories || {}
  const { listofPlaces = [], getPlaces = {} } = places || {}

  return (
    <div className="custom-container desktop-search-mobile px-5 mb-5">
    {/* <div style={{opacity: 1}} className="global-title is-centered">
      <h2 style={{fontSize: '0.2em !important'}}>
        <span>Listings </span>
      </h2>
    </div> */}
    <div class="fixed right-0 top-1/4 transform -translate-y-1/4 z-[99999]">
      <button onClick={openModal} class="bg-[#031B28] hover:bg-[#052639] text-white font-bold py-2 px-4 rounded-l">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
        </svg>
      </button>
    </div>
    <Modal isOpen={isModalOpen} onClose={closeModal}>
    <form className="row g-3">
      <h2 className='fs-4'>
        <span>Advanced Search </span>
      </h2>
      <div className="col-md-3">
        <label style={{fontSize: '16px', marginBottom: '0.35rem'}} htmlFor="inputLocation" className="form-label">Location</label>
        <select
          id="inputType"
          className="form-select"
          name='location'
          value={formData.location} 
          onChange={handleInputChange}
          style={{
            border: 'none',
            borderBottom: '1px solid #000',
            borderRadius: '0',
            padding: '0.375rem 0.75rem',
            backgroundColor: '#dedede',
            outline: 'none'
          }}
        >
          <option value=''>Choose...</option>
          {
            (!places || places.listOfPlaces.length === 0) || places.listOfPlaces ? places.listOfPlaces.map((item, index) => (
              <option key={index} value={item.district}>{item.district}</option>
            )) : <option>...</option>
          }
        </select>
      </div>
      <div className="col-md-3">
        <label style={{fontSize: '16px', marginBottom: '0.35rem'}} htmlFor="inputType" className="form-label">Property Type</label>
        <select
          id="inputType"
          className="form-select"
          name='propertyType'
          value={formData.propertyType} 
          onChange={handleInputChange}
          style={{
            border: 'none',
            borderBottom: '1px solid #000',
            borderRadius: '0',
            padding: '0.375rem 0.75rem',
            backgroundColor: '#dedede',
            outline: 'none'
          }}
        >
          <option value=''>Choose...</option>
          {
            (!categories || categories.listOfCategories.length === 0) || listOfCategories ? listOfCategories.map((item, index) => (
              <option key={index} value={item.id}>{item.name}</option>
            )) : <option>...</option>
          }
        </select>
      </div>
      <div className="col-md-3">
        <label style={{fontSize: '16px', marginBottom: '0.35rem'}} htmlFor="inputEmail4" className="form-label">Price Range</label>
        <select
          id="inputType"
          className="form-select"
          name='priceRange'
          value={formData.priceRange} 
          onChange={handleInputChange}
          style={{
            border: 'none',
            borderBottom: '1px solid #000',
            borderRadius: '0',
            padding: '0.375rem 0.75rem',
            backgroundColor: '#dedede',
            outline: 'none'
          }}
        >
          <option value=''>Choose...</option>
          <option value='0'>Below - 50 M</option>
          <option value='1'>50M - 100M</option>
          <option value='2'>100M - 200M</option>
          <option value='3'>200M - Above</option>
        </select>
        {/* <PriceRangeSlider
          onChange={(newValue) => handleRangeChange('priceRange', newValue)}
          value={formData.priceRange}
        /> */}
      </div>
      <div className="col-md-3">
        <label style={{fontSize: '16px', marginBottom: '0.35rem'}} htmlFor="inputPassword4" className="form-label">Property Size</label>
        <select
          id="inputType"
          className="form-select"
          name='propertySize'
          value={formData.propertySize}
          onChange={handleInputChange}
          style={{
            border: 'none',
            borderBottom: '1px solid #000',
            borderRadius: '0',
            padding: '0.375rem 0.75rem',
            backgroundColor: '#dedede',
            outline: 'none'
          }}
        >
          <option value=''>Choose...</option>
          <option value='0'>20sqm - 50sqm</option>
          <option value='1'>50sqm - 100sqm</option>
          <option value='2'>100sqm - 200sqm</option>
          <option value='3'>200sqm - Above</option>
        </select>
        {/* <SizeRangeSlider 
         onChange={(newValue) => handleRangeChange('propertySize', newValue)}
         value={formData.propertySize}
        /> */}
      </div>
      <h2 className='fs-6'>
        <span style={{fontSize: '0.1em !important'}}>Availability </span>
      </h2>
      <div className='col-md-12'>
        <div className="d-flex flex-wrap gap-2">
          <div style={{fontSize: '16px'}} className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="forRentChecked"
              name="forRent"
              checked={formData.forRent}
              onChange={handleInputChange} 
            />
            <label style={{paddingTop: '3px'}} className="form-check-label" htmlFor="forRentChecked">
              For Rent
            </label>
          </div>
          <div style={{fontSize: '16px'}} className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="forSaleChecked"
              name="forSale"
              checked={formData.forSale} 
              onChange={handleInputChange} 
            />
            <label style={{paddingTop: '3px'}} className="form-check-label" htmlFor="forSaleChecked">
              For Sale
            </label>
          </div>
          <div style={{fontSize: '16px'}} className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="isLandCheck"
              name="isLand"
              checked={formData.isLand} 
              onChange={handleInputChange} 
            />
            <label style={{paddingTop: '3px'}} className="form-check-label" htmlFor="isLandCkeck">
              Land
            </label>
          </div>
        </div>
      </div>
    </form>
    </Modal>
  </div>
  );
};

export default SearchFilters;
