/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchBlogAction } from '../../redux/actions/blog'
import { fetchAllBlogs } from '../../redux/actions/blogs'

function SingleBlog() {
  const { id } = useParams()
  const dispatch = useDispatch()
  useEffect(() =>{
    dispatch(fetchBlogAction(id))
    dispatch(fetchAllBlogs(1, 4))
  }, [dispatch, id])

  const data = useSelector(state => state.blog)
  const blogs = useSelector(state => state.blogs)

  // eslint-disable-next-line no-unused-vars
  const { loading, blog, getBlog, message } = data
  // eslint-disable-next-line no-unused-vars
  const { listOfBlogs, next, currentPage, getBlogs } = blogs

  const topOfPageRef = useRef(null)
  useEffect(() => {
    if (topOfPageRef.current) {
      topOfPageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
    }
  }, [])

  return (
    <>
      <main className="bg-gray-100">
        <div ref={topOfPageRef} style={{marginBottom: 50, marginTop: 120}} className="container mx-auto px-4 py-8">
          <div id="content" className="flex flex-col lg:flex-row lg:space-x-8">
            {/* Main content */}
            <div className="lg:w-2/3">
              <article className="bg-white shadow-lg rounded-lg overflow-hidden">
                <div className="p-6">
                  <p className="text-sm text-gray-600 mb-4">
                    <Link to="/" className="hover:underline">Home</Link> » {blog && blog.title}
                  </p>
                  <h1 style={{fontWeight: 800}} className="entry-title text-3xl font-bold mb-6 fs-3">{blog && blog.title}</h1>
                  <img
                    src={blog && blog.url}
                    alt={blog && blog.title}
                    className="w-full h-100 object-cover mb-6 rounded-lg"
                  />
                  <div className="flex items-center space-x-4 mt-4 mb-6">
                    <img 
                      src={blog && blog.author.profUrl} 
                      alt={`${blog && blog.author.firstName}'s avatar`} 
                      className="w-12 h-12 rounded-full"
                    />
                    <div>
                      <h3 className="font-semibold text-lg">{blog && blog.author.firstName} {blog && blog.author.lastName}</h3>
                    </div>
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: blog && blog.content }} className="prose max-w-noneleading-[1.4]">
                  </div>
                </div>
              </article>
              <div className="mt-8">
                <div className="back-to-link">
                  <Link to='/blog' className="global-btn">Back To Blog</Link>
                </div>
              </div>
            </div>

            {/* Aside */}
            {/* <div className="lg:w-1/3 relative">
              <aside className="lg:fixed lg:w-1/4 mt-8 lg:mt-0 overflow-y-auto max-h-screen pb-8">
                <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                  <div className="p-6">
                    <h2 className="text-xl font-semibold mb-4">Recent Posts</h2>
                    <div className="space-y-4">
                      {listOfBlogs && listOfBlogs.slice(0, 5).map((recentBlog) => (
                        <div key={recentBlog.id}>
                          <Link 
                            to={`/blogs/${recentBlog.id}`} 
                            className="block"
                          >
                            <div className="flex items-center space-x-3">
                              <img 
                                src={recentBlog.url || 'default-image-url.jpg'} 
                                alt={recentBlog.title} 
                                className="w-16 h-16 object-cover rounded"
                              />
                              <div className="flex-1 min-w-0">
                                <h3 className="text-blue-500 hover:underline font-medium truncate">{recentBlog.title}</h3>
                                <p className="text-sm text-gray-500 truncate">{recentBlog.content}</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </aside>
            </div> */}
            <aside className="lg:w-1/3 w-full mt-8 lg:mt-0">
              <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                <div className="p-6">
                  <h2 className="text-xl font-semibold mb-4">Recent Posts</h2>
                  <div className="space-y-4">
                    {listOfBlogs && listOfBlogs.slice(0, 5).map((recentBlog) => (
                      <div key={recentBlog.id}>
                        <Link 
                          to={`/blogs/${recentBlog.slug}`} 
                          className="block"
                        >
                          <div className="flex items-center space-x-3">
                            <img 
                              src={recentBlog.url || 'default-image-url.jpg'} 
                              alt={recentBlog.title} 
                              className="w-32 h-32 object-cover rounded"
                            />
                            <div className="flex-1 min-w-0">

                              <h1 style={{fontWeight: 800, marginTop: 0}} className="entry-title text-3xl font-bold mb-6 fs-6">{recentBlog && recentBlog.title}</h1>
                              {/* <h3 className="entry-title hover:underline font-medium truncate">{recentBlog.title}</h3> */}
                              <p className="text-sm text-gray-500 truncate">{recentBlog.content.replace(/<\/?[^>]+(>|$)/g, "")}</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="bg-white shadow-lg rounded-lg overflow-hidden mt-8">
                <div className="p-6">
                  <img src={require('../../assets/logo/ggb2.png')} alt='ads' />
                </div>
              </div>
              <div className="bg-white shadow-lg rounded-lg overflow-hidden mt-8">
                <div className="p-6">
                  <img src={require('../../assets/logo/roots.png')} alt='ads' />
                </div>
              </div>
              <div className="bg-white shadow-lg rounded-lg overflow-hidden mt-8">
                <div className="p-6">
                  <img src={require('../../assets/logo/ads.png')} alt='ads' />
                </div>
              </div>
            </aside>
          </div>
        </div>
      </main>
    {/* <main>
      <div></div>
  <h2 className="aios-starter-theme-hide-title">Main Content</h2>
  <div className="textwidget custom-html-widget">
    <div className="ip-banner">
      <canvas width="1600" height="350" style={{backgroundImage: `url('${require('../../assets/images/slide-img-1.jpg')}')`}}></canvas>
    </div>
  </div>
  <div id="inner-page-wrapper">
    <div className="container p-4">
      <div id="content-full">
        <article id="content" className="hfeed">
          <p id="breadcrumbs">
            <span>
              <span property="itemListElement" typeof="ListItem">
                <Link property="item" typeof="WebPage" to='/'>
                  <span property="name">Home</span>
                </Link>
                <meta property="position" content="1"/>
              </span> » <span className="breadcrumb_last" property="itemListElement" typeof="ListItem">
                <span property="name">{blog && blog.title}</span>
                <meta property="position" content="2"/>
              </span>
            </span>
          </p>
          <div id="post-204" className="post-204 post type-post status-publish format-standard has-post-thumbnail hentry category-blog">
            <h1 className="entry-title">{blog && blog.title}</h1>
            <div className="entry-thumbnail">
              <img
                width="768"
                height="836"
                src={blog && blog.url}
                className="attachment-full size-full wp-post-image"
                alt=""
                srcset={`${blog && blog.url} 1254w, ${blog && blog.url} 400w, ${blog && blog.url} 1024w, ${blog && blog.url} 768w, ${blog && blog.url} 150w`}
              />
            </div>
            <div className="entry entry-content">
              <p>{blog && blog.content}</p>
            </div>
            <div className="comments-template"></div>
          </div>
          <div className="back-to-link">
            <Link to='/blog' className="global-btn">Back To Blog</Link>
          </div>
          <div className="navigation"></div>
        </article>
      </div>
      <div className="clearfix"></div>
    </div>
  </div>
</main> */}
</>
  )
}

export default SingleBlog