const initState = {
    loading: false,
    message: '',
    listOfProperties: [],
    // listOfDepartures: [],
    // listOfArrivals: [],
    next: undefined,
    currentPage: undefined,
    error: '',
    getProperties: {
      loading: false,
      message: '',
      error: ''
    },
    hasShownToast: false,
    hasShownToastError: false,
  };
  
  export default initState;
  