export const LOGIN_USER_START = 'LOGIN_USER_START';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGIN_USER_END = 'LOGIN_USER_END';

export const SIGNUP_USER_START = 'SIGNUP_USER_START';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_FAILURE = 'SIGNUP_USER_FAILURE';
export const SIGNUP_USER_END = 'SIGNUP_USER_END';

export const CONFIRM_USER_START = 'CONFIRM_USER_START';
export const CONFIRM_USER_SUCCESS = 'CONFIRM_USER_SUCCESS';
export const CONFIRM_USER_FAILURE = 'CONFIRM_USER_FAILURE';
export const CONFIRM_USER_END = 'CONFIRM_USER_END';

export const LOGOUT_USER_START = 'LOGOUT_START';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_USER_FAILURE = 'LOGOUT_FAILURE';
export const LOGOUT_USER_END = 'LOGOUT_END';

export const FETCH_USER_START = 'FETCH_USER_START';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const FETCH_USER_END = 'FETCH_USER_END';

export const CREATE_USER_START = 'CREATE_USER_START';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
export const CREATE_USER_END = 'CREATE_USER_END';

export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
export const DELETE_USER_END = 'DELETE_USER_END';

export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const UPDATE_USER_END = 'UPDATE_USER_END';
