import { animated, useTransition } from '@react-spring/web';

const Modal = ({ isOpen, onClose, image, onNext, onPrev }) => {
  const transition = useTransition(isOpen, {
    from: { opacity: 0, transform: 'scale(0.95)' },
    enter: { opacity: 1, transform: 'scale(1)' },
    leave: { opacity: 0, transform: 'scale(0.95)' },
  });

  return transition((styles, show) =>
    show && (
      <animated.div 
        style={styles} 
        className="fixed inset-0 z-50 flex items-center justify-center bg-black/80"
        onClick={onClose}
      >
        <div 
          className="relative max-w-7xl mx-auto p-4" 
          onClick={e => e.stopPropagation()}
        >
          <animated.img
            src={image}
            alt="Modal Image"
            className="max-h-[90vh] max-w-full object-contain"
          />
          
          <button
            className="absolute left-4 top-1/2 -translate-y-1/2 bg-white/10 hover:bg-white/20 p-2 rounded-full"
            onClick={onPrev}
          >
            <span className="text-white text-2xl">←</span>
          </button>
          
          <button
            className="absolute right-4 top-1/2 -translate-y-1/2 bg-white/10 hover:bg-white/20 p-2 rounded-full"
            onClick={onNext}
          >
            <span className="text-white text-2xl">→</span>
          </button>

          <button
            className="absolute top-4 right-4 text-white bg-white/10 hover:bg-white/20 p-2 rounded-full"
            onClick={onClose}
          >
            ✕
          </button>
        </div>
      </animated.div>
    )
  );
};

export default Modal;
