const initState = {
  loading: false,
  message: '',
  listOfPlaces: [],
  // listOfDepartures: [],
  // listOfArrivals: [],
  next: undefined,
  currentPage: undefined,
  error: '',
  getPlaces: {
    loading: false,
    message: '',
    error: ''
  },
  // getDepartures: {
  //   loading: false,
  //   message: '',
  //   error: ''
  // },
  // getArrivals: {
  //   loading: false,
  //   message: '',
  //   error: ''
  // }
};

export default initState;
