/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import emailjs from '@emailjs/browser';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { useFormik } from 'formik';

const validationSchema = Yup.object({
  firstname: Yup.string()
    .min(2, 'First name must be at least 2 characters')
    .required('First name is required'),
  lastname: Yup.string()
    .min(2, 'Last name must be at least 2 characters')
    .required('Last name is required'),
  phone: Yup.string()
    .matches(/^[0-9]+$/, 'Phone number must contain only digits')
    .min(10, 'Phone number must be at least 10 digits')
    .required('Phone number is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  message: Yup.string()
    .min(10, 'Message must be at least 10 characters')
    .required('Message is required'),
});

function Buyers() {
  const form = useRef();
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      phone: '',
      email: '',
      message: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      sendEmail(values);
      setSubmitting(false);
    }
  });

  const sendEmail = (values) => {
    const formElement = form.current;
    
    console.log(formElement);
    // Update form fields with formik values
    Object.keys(values).forEach(key => {
      const input = formElement.querySelector(`[name="${key}"]`);
      if (input) {
        input.value = values[key];
      }
    });

    emailjs.sendForm('service_460flsj', 'template_3rj007u', formElement, 'Jj5f9N6jzrkBB61b6')
      .then((result) => {
          console.log('SUCCESS!', result.status, result.text);
          // You can add code here to show a success message to the user
          toast('MESSAGE SENT!!!', {
            duration: 4300,
            position: 'top-center',
          
            // Styling
            style: {
              background: 'green',
              color: '#fff',
            },
            // className: '',
          
            // Custom Icon
            icon: '👏',
          
            // Change colors of success/error/loading icon
            iconTheme: {
              primary: '#000',
              secondary: '#fff',
            },
          
            // Aria
            ariaProps: {
              role: 'status',
              'aria-live': 'polite',
            },
          })

          // Navigate after a short delay to ensure the toast is visible
          setTimeout(() => {
            navigate('/req_thanks');
          }, 2000);
      }, (error) => {
          console.log('FAILED...', error);
          // You can add code here to show an error message to the user
      });
  };

  const topOfPageRef = useRef(null)
  useEffect(() => {
    if (topOfPageRef.current) {
      topOfPageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
    }
  }, [])
  return (
    <main>
      <div ref={topOfPageRef}></div>
      <h2 className="aios-starter-theme-hide-title">Main Content</h2>
      {/* <!-- ip banner goes here --> */}
      <div className="textwidget custom-html-widget">
        {/* <!--<div className="ip-banner" data-type="page" data-id="18"><canvas width="1600" height="350" style="background-image: url(https://serioestates.com/wp-content/uploads/2023/01/slide-img-1.jpg)"></canvas></div>
    --> */}
        {/* <div className="custom-ip-banner">
          <canvas width="1530" height="568" style={{backgroundImage: `url(${require('../../assets/images/banner-meet.jpg')})`}}></canvas>
          <div className="custom-ip-tagline">
            <div className="container">Respected. Loyal. Relentless. Discreet.</div>
          </div>
        </div> */}
      </div>
      {/* <!-- ip banner goes here --> */}
      <div style={{marginTop: 50}}></div>
      <div id="inner-page-wrapper">
        <div className="container">
          <div id="content-full">
            <article id="content" className="hfeed">
              <p id="breadcrumbs">
                <span>
                  <span property="itemListElement" typeof="ListItem">
                    <Link property="item" typeof="WebPage" to='/'>
                      <span property="name">Home</span>
                    </Link>
                    <meta property="position" content="1"/>
                  </span> » <span className="breadcrumb_last" property="itemListElement" typeof="ListItem">
                    <span property="name">Contact Us</span>
                    <meta property="position" content="2"/>
                  </span>
                </span>
              </p>
              <div id="post-18" className="post-18 page type-page status-publish hentry">
                <h1 className="entry-title hidden">Contact Us</h1>
                <div className="entry entry-content">
                  <div id="ai-modern-contact" className="ai-modern-contact">
                    <div className="ai-modern-contact-entry">
                      <div className="container">
                        <div className="ai-modern-contact-entry-inner">
                          <div className="ai-modern-contact-photo">
                            <span>
                              <canvas width="550" height="825" style={{backgroundImage: `url(${require('../../assets/images/contact_us.jpg')})`}}></canvas>
                            </span>
                          </div>
                          <div className="ai-modern-contact-main">
                            <div className="global-title">
                              <h2>
                                <small>KigaluXe Group</small>
                                <span>Contact</span>
                              </h2>
                            </div>
                            <ul className="ai-modern-contact-info">
                              <li>
                                <span className="ai-font-phone-alt phone"></span>
                                <a href="tel:+250788501505" className="aios-ai-phone " aria-label="+250788501505">+250788501505</a>
                              </li>
                              <li>
                                <span className="ai-font-envelope envelope"></span>
                                <a className="asis-mailto-obfuscated-email " data-value="info@kigaluxe.estate" href="mailto:info@kigaluxe.estate" aria-label="info@kigaluxe.estate">info@kigaluxe.estate</a>
                              </li>
                              <li>
                                <span className="ai-font-location-c pin"></span>
                                <p>Kicukiro, Kigali, Rwanda</p>
                              </li>
                            </ul>
                            <div className="ai-modern-contact-form">
                              <h2 className="ai-modern-contact-form-title">
                                <strong>Send Us A Message</strong>
                                <span>Have any questions? Get in Touch</span>
                              </h2>
                              <div className="ai-modern-contact-form-container">
                                <div className="wpcf7 no-js" id="wpcf7-f86-p18-o2" lang="en-US" dir="ltr">
                                  <div className="screen-reader-response">
                                    <p role="status" aria-live="polite" aria-atomic="true"></p>
                                    <ul></ul>
                                  </div>
                                  <form 
                                    ref={form} 
                                    onSubmit={formik.handleSubmit} 
                                    className="wpcf7-form init use-floating-validation-tip" 
                                    aria-label="Contact form"
                                  >
                                    <div className="ai-modern-contact-form-field">
                                      <label htmlFor="your-fname" className="ai-modern-contact-form-label">First Name</label>
                                      <span className="wpcf7-form-control-wrap" data-name="your-fname">
                                        <input
                                          id="your-fname"
                                          name="firstname"
                                          type="text"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.firstname}
                                          className={`wpcf7-form-control wpcf7-text wpcf7-validates-as-required ai-modern-contact-form-control 
                                            ${formik.touched.firstname && formik.errors.firstname ? 'border-red-500' : ''}`}
                                          maxLength="400"
                                          aria-required="true"
                                        />
                                        {formik.touched.firstname && formik.errors.firstname ? (
                                          <div className="text-red-500 text-sm mt-1">{formik.errors.firstname}</div>
                                        ) : null}
                                      </span>
                                    </div>

                                    <div className="ai-modern-contact-form-field">
                                      <label htmlFor="your-lname" className="ai-modern-contact-form-label">Last Name</label>
                                      <span className="wpcf7-form-control-wrap" data-name="your-lname">
                                        <input
                                          id="your-lname"
                                          name="lastname"
                                          type="text"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.lastname}
                                          className={`wpcf7-form-control wpcf7-text wpcf7-validates-as-required ai-modern-contact-form-control
                                            ${formik.touched.lastname && formik.errors.lastname ? 'border-red-500' : ''}`}
                                          maxLength="400"
                                          aria-required="true"
                                        />
                                        {formik.touched.lastname && formik.errors.lastname ? (
                                          <div className="text-red-500 text-sm mt-1">{formik.errors.lastname}</div>
                                        ) : null}
                                      </span>
                                    </div>

                                    <div className="ai-modern-contact-form-field">
                                      <label htmlFor="your-phone" className="ai-modern-contact-form-label">Phone Number</label>
                                      <span className="wpcf7-form-control-wrap" data-name="your-phone">
                                        <input
                                          id="your-phone"
                                          name="phone"
                                          type="tel"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.phone}
                                          className={`wpcf7-form-control wpcf7-tel wpcf7-validates-as-required ai-modern-contact-form-control
                                            ${formik.touched.phone && formik.errors.phone ? 'border-red-500' : ''}`}
                                          maxLength="400"
                                          aria-required="true"
                                        />
                                        {formik.touched.phone && formik.errors.phone ? (
                                          <div className="text-red-500 text-sm mt-1">{formik.errors.phone}</div>
                                        ) : null}
                                      </span>
                                    </div>

                                    <div className="ai-modern-contact-form-field">
                                      <label htmlFor="your-email" className="ai-modern-contact-form-label">Email Address</label>
                                      <span className="wpcf7-form-control-wrap" data-name="your-email">
                                        <input
                                          id="your-email"
                                          name="email"
                                          type="email"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.email}
                                          className={`wpcf7-form-control wpcf7-email wpcf7-validates-as-required ai-modern-contact-form-control
                                            ${formik.touched.email && formik.errors.email ? 'border-red-500' : ''}`}
                                          maxLength="400"
                                          aria-required="true"
                                        />
                                        {formik.touched.email && formik.errors.email ? (
                                          <div className="text-red-500 text-sm mt-1">{formik.errors.email}</div>
                                        ) : null}
                                      </span>
                                    </div>

                                    <div className="ai-modern-contact-form-field lg">
                                      <label htmlFor="your-message" className="ai-modern-contact-form-label">Message</label>
                                      <span className="wpcf7-form-control-wrap" data-name="your-message">
                                        <textarea
                                          id="your-message"
                                          name="message"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.message}
                                          className={`wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required ai-modern-contact-form-control
                                            ${formik.touched.message && formik.errors.message ? 'border-red-500' : ''}`}
                                          rows="10"
                                          maxLength="2000"
                                          aria-required="true"
                                        />
                                        {formik.touched.message && formik.errors.message ? (
                                          <div className="text-red-500 text-sm mt-1">{formik.errors.message}</div>
                                        ) : null}
                                      </span>
                                      <button 
                                        type="submit" 
                                        className="ai-modern-contact-form-submit wpcf7-form-control has-spinner wpcf7-submit"
                                        disabled={formik.isSubmitting}
                                      >
                                        <i className="ai-font-paper-airplane-alt" aria-hidden="true"></i>
                                      </button>
                                    </div>
                                    <div className="wpcf7-response-output" aria-hidden="true"></div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="ai-modern-contact-map" data-aos="fade-up">
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d211469.09442852973!2d-118.612546!3d34.0898987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xaf204d967f3babf7%3A0x8ed479f7871fe87b!2sSerio%20Group!5e0!3m2!1sen!2sph!4v1680573923808!5m2!1sen!2sph" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div> */}
                  </div>
                </div>
                <div className="comments-template"></div>
              </div>
            </article>
            {/* <!-- end #content --> */}
          </div>
          {/* <!-- end #content-full --> */}
          <div className="clearfix"></div>
        </div>
        {/* <!-- end of #inner-page-wrapper .inner --> */}
      </div>
      {/* <!-- end of #inner-page-wrapper --> */}
    </main>
  )
}

export default Buyers