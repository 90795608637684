/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { animated, useSpring, useTransition, useSprings } from '@react-spring/web';
import { useRef, useState, useEffect } from 'react';

const Gallery = ({ 
  imgs, 
  property,
  className = '', 
  style = {} 
}) => {
  const propertyPrice = property?.price ? `RWF ${property.price}` : '';
  // States
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const scrollContainerRef = useRef(null);

  // Handle thumbnail click
  const handleClick = (index) => {
    setCurrentIndex(index);
  };

  // Spring configurations
  const springConfig = {
    tension: 100,
    friction: 40,
    precision: 0.1
  };

  // Animation for main image
  const transitions2 = useTransition(currentIndex, {
    from: { 
      opacity: 0,
      transform: 'scale(0.8)'
    },
    enter: { 
      opacity: 1,
      transform: 'scale(1)'
    },
    leave: { 
      opacity: 0,
      transform: 'scale(0.9)'
    },
    config: {
      ...springConfig,
      tension: 280,
      friction: 20,
    },
    key: currentIndex,
  });


  // Animations for thumbnails
  const thumbnailSprings = useSprings(
    imgs.length,
    imgs.map((_, index) => ({
      scale: hoveredIndex === index ? 1.05 : 1,
      opacity: currentIndex === index ? 1 : 0.7,
      config: springConfig
    }))
  );

  // Navigation handlers
  const handleNext = () => {
    setCurrentIndex((prev) => (prev + 1) % imgs.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prev) => (prev - 1 + imgs.length) % imgs.length);
  };

  return (
    <div 
      className={`w-full h-full ${className}  z-9999`}
      style={style}
    >
      <div className="flex gap-6 h-full">
        {/* Main Image Section */}
        <div className="w-2/3 h-full">
          {transitions2((style, item) => (
            <animated.div style={style} className="h-full">
              <div 
                className="relative h-full" 
                onClick={() => setIsModalOpen(true)}
              >
                <div
                  className='w-full h-full'
                  style={{
                    position: 'relative',
                    overflow: 'hidden'
                }}>
                <div className="ld-grid-price">
                    <span>{propertyPrice}</span>
                </div>
                  <animated.img
                    src={imgs[item]}
                    alt="Main Gallery Image"
                    className="w-full h-full object-cover rounded-xl cursor-pointer shadow-lg lazyload main-img"
                  />
                </div>
              </div>
            </animated.div>
          ))}
        </div>

        {/* Thumbnails Section */}
        {property && (
          <div className="w-1/3 h-full">
            <div
              ref={scrollContainerRef}
              className="h-full p-2 overflow-y-auto scroll-smooth pr-2 scrollbar-none"
              style={{
                maskImage: 'linear-gradient(to bottom, rgba(0,0,0,0.95), black 15%, black 85%, rgba(0,0,0,0.95))',
                WebkitMaskImage: 'linear-gradient(to bottom, rgba(0,0,0,0.95), black 15%, black 85%, rgba(0,0,0,0.95))',
                msOverflowStyle: 'none',  /* IE and Edge */
                scrollbarWidth: 'none',   /* Firefox */
                '&::-webkit-scrollbar': {
                  display: 'none'
                }         /* Chrome, Safari and Opera */
              }}
            >
              <div className="flex flex-col gap-4">
                {thumbnailSprings.map((springs, index) => (
                  <animated.div
                    key={index}
                    style={springs}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                    className="relative aspect-[16/9] w-full flex-shrink-0"
                  >
                    <img
                      src={imgs[index]}
                      alt={`Gallery Image ${index + 1}`}
                      onClick={() => handleClick(index)}
                      className={`w-full h-full object-cover rounded-lg cursor-pointer 
                        shadow-md transition-all duration-300
                        ${currentIndex === index ? 'ring-2 ring-blue-500' : ''}`}
                    />
                  </animated.div>
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Modal */}
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          image={imgs[currentIndex]}
          onNext={handleNext}
          onPrev={handlePrev}
          currentIndex={currentIndex}
          totalImages={imgs?.length}
        />
      </div>
    </div>
  );
};

// Modal Component
const Modal = ({ isOpen, onClose, image, onNext, onPrev, currentIndex, totalImages }) => {
  const transition = useTransition(isOpen, {
    from: { opacity: 0, scale: 0.95 },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0, scale: 0.95 },
    config: { tension: 300, friction: 20 }
  });

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (!isOpen) return;
      
      if (e.key === 'ArrowRight') onNext();
      if (e.key === 'ArrowLeft') onPrev();
      if (e.key === 'Escape') onClose();
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [isOpen, onNext, onPrev, onClose]);

  return transition((styles, show) =>
    show && (
      <animated.div
        style={styles}
        className="fixed inset-0 z-50 flex items-center justify-center bg-black/80"
        onClick={onClose}
      >
        <div 
          className="relative max-w-7xl mx-auto p-4" 
          onClick={e => e.stopPropagation()}
        >
          <img
            src={image}
            alt="Enlarged view"
            className="max-h-[100vh] max-w-full object-contain rounded-lg"
          />
          
          {/* Navigation buttons */}
          <button
            className="absolute left-4 top-1/2 -translate-y-1/2 bg-white/10 
              hover:bg-white/20 p-4 rounded-full text-black"
            onClick={(e) => {
              e.stopPropagation();
              onPrev();
            }}
          >
            ←
          </button>
          
          <button
            className="absolute right-4 top-1/2 -translate-y-1/2 bg-white/10 
              hover:bg-white/20 p-4 rounded-full text-black"
            onClick={(e) => {
              e.stopPropagation();
              onNext();
            }}
          >
            →
          </button>

          {/* Close button */}
          <button
            className="absolute top-4 right-4 text-white bg-white/10 
              hover:bg-white/20 p-2 rounded-full"
            onClick={onClose}
          >
            ✕
          </button>

          {/* Image counter */}
          <div className="absolute bottom-4 left-1/2 -translate-x-1/2 
            text-white bg-black/50 px-4 py-2 rounded-full">
            {currentIndex + 1} / {totalImages}
          </div>
        </div>
      </animated.div>
    )
  );
};

export default Gallery;
