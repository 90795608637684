export const FETCH_PROPERTY_START = 'FETCH_PROPERTY_START';
export const FETCH_PROPERTY_SUCCESS = 'FETCH_PROPERTY_SUCCESS';
export const FETCH_PROPERTY_FAILURE = 'FETCH_PROPERTY_FAILURE';
export const FETCH_PROPERTY_END = 'FETCH_PROPERTY_END';

export const CREATE_PROPERTY_START = 'CREATE_PROPERTY_START';
export const CREATE_PROPERTY_SUCCESS = 'CREATE_PROPERTY_SUCCESS';
export const CREATE_PROPERTY_FAILURE = 'CREATE_PROPERTY_FAILURE';
export const CREATE_PROPERTY_END = 'CREATE_PROPERTY_END';

export const UPDATE_PROPERTY_START = 'UPDATE_PROPERTY_START';
export const UPDATE_PROPERTY_SUCCESS = 'UPDATE_PROPERTY_SUCCESS';
export const UPDATE_PROPERTY_FAILURE = 'UPDATE_PROPERTY_FAILURE';
export const UPDATE_PROPERTY_END = 'UPDATE_PROPERTY_END';

export const DELETE_PROPERTY_START = 'DELETE_PROPERTY_START';
export const DELETE_PROPERTY_SUCCESS = 'DELETE_PROPERTY_SUCCESS';
export const DELETE_PROPERTY_FAILURE = 'DELETE_PROPERTY_FAILURE';
export const DELETE_PROPERTY_END = 'DELETE_PROPERTY_END';
