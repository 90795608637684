/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import emailjs from '@emailjs/browser';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { DatePicker, TimePicker} from 'antd';

import './antd.css'

function ContactUs() {
  const form = useRef();
  const navigate = useNavigate();

  // Validation Schema
  const validationSchema = Yup.object({
    firstname: Yup.string()
      .required('Full name is required')
      .min(2, 'Name must be at least 2 characters'),
    lastname: Yup.string()
      .required('Property address is required')
      .min(5, 'Address must be at least 5 characters'),
    phone: Yup.string()
      .required('Phone number is required')
      .matches(/^[0-9]+$/, 'Phone number must contain only digits')
      .min(10, 'Phone number must be at least 10 digits'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    schedule_date: Yup.date()
      .required('Schedule date is required'),
    schedule_time: Yup.string()
      .required('Schedule time is required'),
    message: Yup.string()
      .required('Message is required')
      .min(10, 'Message must be at least 10 characters')
  });

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      phone: '',
      email: '',
      schedule_date: null,
      schedule_time: null,
      message: ''
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      const formElement = form.current;
      
      console.log(formElement);
      // Update form fields with formik values
      Object.keys(values).forEach(key => {
        const input = formElement.querySelector(`[name="${key}"]`);
        if (input) {
          input.value = values[key];
        }
      });
    
      emailjs.sendForm('service_460flsj', 'template_3rj007u', formElement, 'Jj5f9N6jzrkBB61b6')
        .then((result) => {
          console.log('SUCCESS!', result.status, result.text);
          toast('MESSAGE SENT!!!', {
            duration: 4300,
            position: 'top-center',
            style: {
              background: 'green',
              color: '#fff',
            },
            icon: '👏',
            iconTheme: {
              primary: '#000',
              secondary: '#fff',
            },
            ariaProps: {
              role: 'status',
              'aria-live': 'polite',
            },
          });

          setTimeout(() => {
            navigate('/confirmed');
          }, 2000);
        })
        .catch((error) => {
          console.log('FAILED...', error);
          toast.error('Failed to send message. Please try again.');
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const topOfPageRef = useRef(null)
  useEffect(() => {
    if (topOfPageRef.current) {
      topOfPageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
    }
  }, [])
  return (
    <main>
      <div ref={topOfPageRef}></div>
      <h2 className="aios-starter-theme-hide-title">Main Content</h2>
      {/* <!-- ip banner goes here --> */}
      <div className="textwidget custom-html-widget">
        {/* <!--<div className="ip-banner" data-type="page" data-id="18"><canvas width="1600" height="350" style="background-image: url(https://serioestates.com/wp-content/uploads/2023/01/slide-img-1.jpg)"></canvas></div>
    --> */}
        {/* <div className="custom-ip-banner">
          <canvas width="1530" height="568" style={{backgroundImage: `url(${require('../../assets/images/banner-meet.jpg')})`}}></canvas>
          <div className="custom-ip-tagline">
            <div className="container">Respected. Loyal. Relentless. Discreet.</div>
          </div>
        </div> */}
      </div>
      {/* <!-- ip banner goes here --> */}
      <div style={{marginTop: 50}}></div>
      <div id="inner-page-wrapper">
        <div className="container">
          <div id="content-full">
            <article id="content" className="hfeed">
              <p id="breadcrumbs">
                <span>
                  <span property="itemListElement" typeof="ListItem">
                    <Link property="item" typeof="WebPage" to='/'>
                      <span property="name">Home</span>
                    </Link>
                    <meta property="position" content="1"/>
                  </span> » <span className="breadcrumb_last" property="itemListElement" typeof="ListItem">
                    <span property="name">Contact Us</span>
                    <meta property="position" content="2"/>
                  </span>
                </span>
              </p>
              <div id="post-18" className="post-18 page type-page status-publish hentry">
                <h1 className="entry-title hidden">Contact Us</h1>
                <div className="entry entry-content">
                  <div id="ai-modern-contact" className="ai-modern-contact">
                    <div className="ai-modern-contact-entry">
                      <div className="container">
                        <div className="ai-modern-contact-entry-inner">
                          <div className="ai-modern-contact-photo">
                            <span>
                              <canvas width="550" height="825" style={{backgroundImage: `url(${require('../../assets/images/contact_us.jpg')})`}}></canvas>
                            </span>
                          </div>
                          <div className="ai-modern-contact-main">
                            <div className="global-title">
                              <h2>
                                <small>KigaluXe Group</small>
                                <span>Contact</span>
                              </h2>
                            </div>
                            <ul className="ai-modern-contact-info">
                              <li>
                                <span className="ai-font-phone-alt phone"></span>
                                <a href="tel:+250788501505" className="aios-ai-phone " aria-label="+250788501505">+250788501505</a>
                              </li>
                              <li>
                                <span className="ai-font-envelope envelope"></span>
                                <a className="asis-mailto-obfuscated-email " data-value="info@kigaluxe.estate" href="mailto:info@kigaluxe.estate" aria-label="info@kigaluxe.estate">info@kigaluxe.estate</a>
                              </li>
                              <li>
                                <span className="ai-font-location-c pin"></span>
                                <p>Kicukiro, Kigali, Rwanda</p>
                              </li>
                            </ul>
                            <div className="ai-modern-contact-form">
                              <h2 className="ai-modern-contact-form-title">
                                <strong>Send Us A Message</strong>
                                <span>Have any questions? Get in Touch</span>
                              </h2>
                              <div className="ai-modern-contact-form-container">
                                <div className="wpcf7 no-js" id="wpcf7-f86-p18-o2" lang="en-US" dir="ltr">
                                  <div className="screen-reader-response">
                                    <p role="status" aria-live="polite" aria-atomic="true"></p>
                                    <ul></ul>
                                  </div>
                                  <form 
                                    ref={form} 
                                    onSubmit={formik.handleSubmit} 
                                    className="wpcf7-form init use-floating-validation-tip" 
                                    aria-label="Contact form"
                                  >
                                    <div className="ai-modern-contact-form-field">
                                      <label htmlFor="firstname" className="ai-modern-contact-form-label">Full Name</label>
                                      <span className="wpcf7-form-control-wrap">
                                        <input
                                          id="firstname"
                                          type="text"
                                          {...formik.getFieldProps('firstname')}
                                          className={`wpcf7-form-control wpcf7-text wpcf7-validates-as-required ai-modern-contact-form-control
                                            ${formik.touched.firstname && formik.errors.firstname ? 'border-red-500' : ''}`}
                                        />
                                        {formik.touched.firstname && formik.errors.firstname && (
                                          <div className="text-red-500 text-sm mt-1">{formik.errors.firstname}</div>
                                        )}
                                      </span>
                                    </div>

                                    <div className="ai-modern-contact-form-field">
                                      <label htmlFor="lastname" className="ai-modern-contact-form-label">Property Address</label>
                                      <span className="wpcf7-form-control-wrap">
                                        <input
                                          id="lastname"
                                          type="text"
                                          {...formik.getFieldProps('lastname')}
                                          className={`wpcf7-form-control wpcf7-text wpcf7-validates-as-required ai-modern-contact-form-control
                                            ${formik.touched.lastname && formik.errors.lastname ? 'border-red-500' : ''}`}
                                        />
                                        {formik.touched.lastname && formik.errors.lastname && (
                                          <div className="text-red-500 text-sm mt-1">{formik.errors.lastname}</div>
                                        )}
                                      </span>
                                    </div>

                                    <div className="ai-modern-contact-form-field">
                                      <label htmlFor="phone" className="ai-modern-contact-form-label">Phone Number</label>
                                      <span className="wpcf7-form-control-wrap">
                                        <input
                                          id="phone"
                                          type="tel"
                                          {...formik.getFieldProps('phone')}
                                          className={`wpcf7-form-control wpcf7-tel wpcf7-validates-as-required ai-modern-contact-form-control
                                            ${formik.touched.phone && formik.errors.phone ? 'border-red-500' : ''}`}
                                        />
                                        {formik.touched.phone && formik.errors.phone && (
                                          <div className="text-red-500 text-sm mt-1">{formik.errors.phone}</div>
                                        )}
                                      </span>
                                    </div>

                                    <div className="ai-modern-contact-form-field">
                                      <label htmlFor="email" className="ai-modern-contact-form-label">Email Address</label>
                                      <span className="wpcf7-form-control-wrap">
                                        <input
                                          id="email"
                                          type="email"
                                          {...formik.getFieldProps('email')}
                                          className={`wpcf7-form-control wpcf7-email wpcf7-validates-as-required ai-modern-contact-form-control
                                            ${formik.touched.email && formik.errors.email ? 'border-red-500' : ''}`}
                                        />
                                        {formik.touched.email && formik.errors.email && (
                                          <div className="text-red-500 text-sm mt-1">{formik.errors.email}</div>
                                        )}
                                      </span>
                                    </div>

                                    <div className="ai-modern-contact-form-field">
                                      <label htmlFor="schedule_date" className="ai-modern-contact-form-label">Schedule Date</label>
                                      <span className="wpcf7-form-control-wrap" data-name="your-phone">
                                        <DatePicker
                                          id="schedule_date"
                                          className={`wpcf7-form-control wpcf7-tel wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-tel ai-modern-contact-form-control custom-datepicker
                                            ${formik.touched.schedule_date && formik.errors.schedule_date ? 'error-field' : ''}`}
                                          style={{ borderRadius: 0 }}
                                          name="schedule_date"
                                          format="dddd, MMMM D, YYYY"
                                          value={formik.values.schedule_date}
                                          onChange={(date) => formik.setFieldValue('schedule_date', date)}
                                          onBlur={() => formik.setFieldTouched('schedule_date', true)}
                                        />
                                        {formik.touched.schedule_date && formik.errors.schedule_date && (
                                          <div  className="text-red-500 text-sm mt-1">{formik.errors.schedule_date}</div>
                                        )}
                                      </span>
                                    </div>

                                    <div className="ai-modern-contact-form-field">
                                      <label htmlFor="schedule_time" className="ai-modern-contact-form-label">And Hour</label>
                                      <span className="wpcf7-form-control-wrap" data-name="your-email">
                                        <TimePicker
                                          id="schedule_time"
                                          className={`wpcf7-form-control wpcf7-tel wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-tel ai-modern-contact-form-control custom-datepicker
                                            ${formik.touched.schedule_time && formik.errors.schedule_time ? 'error-field' : ''}`}
                                          style={{ borderRadius: 0 }}
                                          name="schedule_time"
                                          format="hh:mm A"
                                          value={formik.values.schedule_time}
                                          onChange={(time) => formik.setFieldValue('schedule_time', time)}
                                          onBlur={() => formik.setFieldTouched('schedule_time', true)}
                                        />
                                        {formik.touched.schedule_time && formik.errors.schedule_time && (
                                          <div  className="text-red-500 text-sm mt-1">{formik.errors.schedule_time}</div>
                                        )}
                                      </span>
                                    </div>

                                    <div className="ai-modern-contact-form-field lg">
                                      <label htmlFor="message" className="ai-modern-contact-form-label">Message</label>
                                      <span className="wpcf7-form-control-wrap">
                                        <textarea
                                          id="message"
                                          {...formik.getFieldProps('message')}
                                          className={`wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required ai-modern-contact-form-control
                                            ${formik.touched.message && formik.errors.message ? 'border-red-500' : ''}`}
                                        />
                                        {formik.touched.message && formik.errors.message && (
                                          <div className="text-red-500 text-sm mt-1">{formik.errors.message}</div>
                                        )}
                                      </span>
                                      <button
                                        type="submit"
                                        disabled={formik.isSubmitting}
                                        className="ai-modern-contact-form-submit wpcf7-form-control has-spinner wpcf7-submit"
                                      >
                                        <i className="ai-font-paper-airplane-alt" aria-hidden="true"></i>
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="ai-modern-contact-map" data-aos="fade-up">
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d211469.09442852973!2d-118.612546!3d34.0898987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xaf204d967f3babf7%3A0x8ed479f7871fe87b!2sSerio%20Group!5e0!3m2!1sen!2sph!4v1680573923808!5m2!1sen!2sph" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div> */}
                  </div>
                </div>
                <div className="comments-template"></div>
              </div>
            </article>
            {/* <!-- end #content --> */}
          </div>
          {/* <!-- end #content-full --> */}
          <div className="clearfix"></div>
        </div>
        {/* <!-- end of #inner-page-wrapper .inner --> */}
      </div>
      {/* <!-- end of #inner-page-wrapper --> */}
    </main>
  )
}

export default ContactUs