const initState = {
  loading: false,
  message: '',
  category: '',
  error: '',
  getCategory: {
    loading: false,
    message: '',
    error: ''
  }
};
  
export default initState;
  