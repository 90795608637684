const initState = {
  loading: false,
  message: '',
  place: '',
  error: '',
  getPlace: {
    loading: false,
    message: '',
    error: ''
  }
};

export default initState;
  