/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { debounce } from 'lodash';
import img1 from '../../assets/images/demo-blog-1.jpg'
import img2 from '../../assets/images/demo-blog-2.jpg'
import img3 from '../../assets/images/demo-blog-3.jpg'
import { Container } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { fetchAllBlogs } from '../../redux/actions/blogs'
import { Link } from 'react-router-dom'

function Blogs() {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchAllBlogs(1, 4))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const blogs = useSelector(state => state.blogs)

  // eslint-disable-next-line no-unused-vars
  const { loading, listOfBlogs, next, currentPage, getBlogs } = blogs
  console.log(listOfBlogs);

  const topOfPageRef = useRef(null)
  useEffect(() => {
    if (topOfPageRef.current) {
      topOfPageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
    }
  }, [])

  const handleScroll = () => {
    const listingRow = document.querySelector('.hfeed');
    if (!listingRow) return;
  
    const rect = listingRow.getBoundingClientRect();
    const isAtBottom = rect.bottom <= window.innerHeight + 100; // 100px threshold
  
    if (isAtBottom && next && !loading) {
      setPage(prevPage => prevPage + 1);
      dispatch(fetchAllBlogs(page + 1, 4, true));
    }
  };
  

  const debouncedHandleScroll = debounce(handleScroll, 200);

  useEffect(() => {
    window.addEventListener('scroll', debouncedHandleScroll);
    return () => window.removeEventListener('scroll', debouncedHandleScroll);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [next, loading, page]);

  return (
    <main>
      <div ref={topOfPageRef}></div>
      <h2 className="aios-starter-theme-hide-title">Main Content</h2>
      {/* <!-- ip banner goes here --> */}
      <div className="textwidget custom-html-widget">
        <div className="ip-banner" data-type="Blog" data-id="4">
          <canvas width="1600" height="350" style={{backgroundImage: `url(images/slide-img-1.jpg)`}}></canvas>
        </div>
      </div>
      {/* <!-- ip banner goes here --> */}
      <div style={{marginTop: '50px'}}></div>
      <div id="inner-page-wrapper">
        <Container>
          <div style={{ marginTop: 80, padding: 20, marginBottom: 80 }} id="content-full">
            <section id="content" className="hfeed">
              <p id="breadcrumbs">
                <span>
                  <span property="itemListElement" typeof="ListItem">
                    <a property="item" typeof="WebPage" href="/">
                      <span property="name">Home</span>
                    </a>
                    <meta property="position" content="1"/>
                  </span> » <span className="breadcrumb_last" property="itemListElement" typeof="ListItem">
                    <span property="name">Blog</span>
                    <meta property="position" content="2"/>
                  </span>
                </span>
              </p>
              <h1 className="archive-title"> Blogs </h1>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
              {
                listOfBlogs.length > 0 && listOfBlogs.map((blog, index) => (
                  <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden transition-transform duration-300 hover:scale-105">
                    <img 
                      src={blog.url || img1} 
                      alt={blog.title} 
                      className="w-full h-50 object-cover object-center"
                    />
                    <div className="p-6">
                      <h2 className="text-xl font-semibold mb-2 line-clamp-2 fs-6">{blog.title}</h2>
                      <p className="text-gray-600 mb-4 line-clamp-2">{blog.content.replace(/<\/?[^>]+(>|$)/g, "")}</p>
                      <div
                        className="back-to-link mb-3"
                        style={{ 
                          transform: 'scale(0.8)', 
                          transformOrigin: 'top left'
                        }}
                      >
                        <Link to={`/blogs/${blog.slug}`} className="global-btn">Read more</Link>
                      </div>
                    </div>
                  </div>
                ))
              }
              </div>

              {/* Loading indicator */}
              {loading && (
                <div className="text-center py-4">
                  <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]">
                    <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                      Loading...
                    </span>
                  </div>
                </div>
              )}

              {/* End of content indicator */}
              {!next && !loading && listOfBlogs?.length > 0 && (
                <div className="text-center py-4 text-gray-600">
                  You've reached the end of the list
                </div>
              )}
              {/* {
                listOfBlogs.length > 0 ? (
              <div className="row archive-list">
                <article id="post-204" className="col-md-12 article-long post-204 post type-post status-publish format-standard has-post-thumbnail hentry category-blog" data-aios-reveal="true" data-aios-animation="fadeIn" data-aios-animation-delay="0s" data-aios-animation-reset="false" data-aios-animation-offset="0.3">
                  <div className="entry">
                    <div className="archive-thumbnail">
                      <Link to={`/blogs/${listOfBlogs ? listOfBlogs[0].id : ''}`}>
                        <canvas width="540" height="360" style={{backgroundImage: `url(${listOfBlogs ? listOfBlogs[0].url : img3})`}}></canvas>
                      </Link>
                    </div>
                    <div className="archive-content archive-has-thumbnail">
                      <h2 className="archive-subtitle">
                        <Link to={`/blogs/${listOfBlogs && listOfBlogs[0].id}`}>{listOfBlogs && listOfBlogs[0].title}</Link>
                      </h2>
                      <p>{listOfBlogs && listOfBlogs[0].content.slice(0, 400)}...</p>
                      <p>
                        <Link className="archive-more" to={`/blogs/${listOfBlogs && listOfBlogs[0].id}`}>Read more</Link>
                      </p>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </article>
                <article id="post-201" className="col-md-6 post-201 post type-post status-publish format-standard has-post-thumbnail hentry category-blog" data-aios-reveal="true" data-aios-animation="fadeIn" data-aios-animation-delay="0.2s" data-aios-animation-reset="false" data-aios-animation-offset="0.3">
                  <div className="entry">
                    <div className="archive-thumbnail">
                      <Link to={`/blogs/${listOfBlogs && listOfBlogs[1].id}`}>
                        <canvas width="540" height="360" style={{backgroundImage: `url(${listOfBlogs ? listOfBlogs[1].url : img1})`}}></canvas>
                      </Link>
                    </div>
                    <div className="archive-content archive-has-thumbnail">
                      <h2 className="archive-subtitle">
                        <Link to={`/blogs/${listOfBlogs && listOfBlogs[1].id}`}>{listOfBlogs && listOfBlogs[1].title}</Link>
                      </h2>
                      <p>{listOfBlogs && listOfBlogs[1].content.slice(0, 400)}...</p>
                      <p>
                        <Link className="archive-more"  to={`/blogs/${listOfBlogs && listOfBlogs[1].id}`}>Read more</Link>
                      </p>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </article>
                <article id="post-198" className="col-md-6 post-198 post type-post status-publish format-standard has-post-thumbnail hentry category-blog" data-aios-reveal="true" data-aios-animation="fadeIn" data-aios-animation-delay="0.2s" data-aios-animation-reset="false" data-aios-animation-offset="0.3">
                  <div className="entry">
                    <div className="archive-thumbnail">
                      <Link to={`/blogs/${listOfBlogs && listOfBlogs[2].id}`}>
                        <canvas width="540" height="360" style={{backgroundImage: `url(${listOfBlogs ? listOfBlogs[2].url : img2})`}}></canvas>
                      </Link>
                    </div>
                    <div className="archive-content archive-has-thumbnail">
                      <h2 className="archive-subtitle">
                        <Link to={`/blogs/${listOfBlogs && listOfBlogs[2].id}`}>{listOfBlogs && listOfBlogs[2].title}</Link>
                      </h2>
                      <p>{listOfBlogs && listOfBlogs[2].content.slice(0, 400)}...</p>
                      <p>
                        <Link className="archive-more" to={`/blogs/${listOfBlogs && listOfBlogs[2].id}`}>Read more</Link>
                      </p>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </article>
              </div>
                ) : ''
              } */}

              <div className="page-links"></div>
            </section>
            {/* <!-- end #content --> */}
          </div>
          {/* <!-- end #content-sidebar --> */}
          <div className="clearfix"></div>
        </Container>
        {/* <!-- end of #inner-page-wrapper .inner --> */}
      </div>
      {/* <!-- end of #inner-page-wrapper --> */}
    </main>
  )
}

export default Blogs