export const FETCH_BLOG_START = 'FETCH_BLOG_START';
export const FETCH_BLOG_SUCCESS = 'FETCH_BLOG_SUCCESS';
export const FETCH_BLOG_FAILURE = 'FETCH_BLOG_FAILURE';
export const FETCH_BLOG_END = 'FETCH_BLOG_END';

export const CREATE_BLOG_START = 'CREATE_BLOG_START';
export const CREATE_BLOG_SUCCESS = 'CREATE_BLOG_SUCCESS';
export const CREATE_BLOG_FAILURE = 'CREATE_BLOG_FAILURE';
export const CREATE_BLOG_END = 'CREATE_BLOG_END';

export const UPDATE_BLOG_START = 'UPDATE_BLOG_START';
export const UPDATE_BLOG_SUCCESS = 'UPDATE_BLOG_SUCCESS';
export const UPDATE_BLOG_FAILURE = 'UPDATE_BLOG_FAILURE';
export const UPDATE_BLOG_END = 'UPDATE_BLOG_END';

export const DELETE_BLOG_START = 'DELETE_BLOG_START';
export const DELETE_BLOG_SUCCESS = 'DELETE_BLOG_SUCCESS';
export const DELETE_BLOG_FAILURE = 'DELETE_BLOG_FAILURE';
export const DELETE_BLOG_END = 'DELETE_BLOG_END';
